<template>
  <header class="xs:py-20 py-36 text-white bg-cover" :style="{ 'background-image': 'url(' + require('/src/assets/images/background_clipped.jpg') + ')' }">
    <h1 class="text-center mb-5">Test na osobowość</h1>
    <p class="text-center xs:w-280 w-480 mx-auto mb-5">Wykonaj krótki test by zobaczyć, który z czterech typów osobowości pasuje do Ciebie najlepiej.</p>
      <div class="flex flex-col md:flex-row justify-center items-center text-black">
        <Button>
          <router-link :to="{ name: 'Test' }">
            <div class="px-12 py-3">
              Zacznij test
            </div>
          </router-link>
        </Button>
        <Button
          v-if="showLastResult"
          class="customButton"
          customClass="text-center"
        >
          <router-link :to="{ name: 'Results' }">
            <div class="px-6 py-3">
                Zobacz ostatni wynik
            </div>
          </router-link>
        </Button>
      </div>
  </header>

  <section class="bg-gray-100 py-8">
    <h1 class="xs:w-325 text-center mx-auto mb-8">Co daje wykonanie testu?</h1>

    <div class="sm:flex sm:flex-col md:flex-row md:justify-center">
      <Benefit :icon="compassIcon" desc="Odkrycie swojego typu osobowości"/>
      <Benefit :icon="peopleIcon" desc="Lepsze zrozumienie siebie oraz innych" class="md:mx-6"/>
      <Benefit :icon="ribbonIcon" desc="Poznanie swoich dominujących cech"/>
    </div>
  </section>

  <section class="py-8">
    <h1 class="text-center mb-4">Jakie są typy osobowości?</h1>
    <p class="text-center xs:w-300 w-680 mx-auto">Każdemu z nas można przypisać jeden lub dwa z czterech typów: sangwinik, choleryk, flegmatyk oraz melancholik.</p>
    <p class="text-center xs:w-300 w-680 mx-auto">Każdy z wymienionych powyżej typów osobowości posiada wyróżniający ją zestaw cech oraz zachowań, na podstawie których możemy określić, który z nich najlepiej nas opisuje.</p>
    <p class="text-center xs:w-300 w-680 mb-0 mx-auto">Wykonanie testu pozwala poznać nasz główny (dominujący) typ osobowości oraz drugi, który jest dopełnieniem pierwszego.</p>
  </section>

  <section class="bg-gray-100 p-8">
    <p class="text-center w-325 mx-auto">Wykonaj test i przekonaj się, który typ osobowości najlepiej Cię opisuje!</p>
    <Button class="mx-auto text-black w-max">
      <router-link :to="{ name: 'Test' }">
        <div class="px-12 py-3">
          Zacznij test
        </div>
      </router-link>
    </Button>
  </section>
  <Footer />
</template>

<script>
import Benefit from '../components/Benefit'
import Button from '../components/Button'
import Footer from '../components/TheFooter'

export default {
  name: 'Home',
  components: {
    Benefit,
    Button,
    Footer
  },
  data() {
    return {
      compassIcon: require('/src/assets/icons/ic_fluent_compass_northwest_24_regular.svg'),
      peopleIcon: require('/src/assets/icons/ic_fluent_people_team_24_regular.svg'),
      ribbonIcon: require('/src/assets/icons/ic_fluent_ribbon_star_24_regular.svg'),
    }
  },
  computed: {
    showLastResult() {
      const data = localStorage.getItem('lastTestResult')
      if (data) {
        // const parsed = JSON.parse(data)
        return true
      }
      
      return false
    }
  }
}
</script>

<style scoped>

</style>