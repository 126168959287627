<template>
    <div v-if="data !== null">
        <Chart :chartData="chartData" />
        <div class="question flex flex-col justify-center xs:w-11/12 sm:w-680 mx-auto">
            <h1 class="text-center mt-10 mb-4" :style="{ color: dominant.color }">Jesteś {{ dominant.trait }}iem!</h1>
            <p class="text-center" :style="{ color: complementary.color }">A Twoje dopełnienie to {{ complementary.trait }}</p>

            <hr class="my-4">
            <p class="text-center mb-1">Uzyskane punkty według typu osobowości:</p>
            <ul class="text-center">
                <li v-for="trait in data" :key="trait.trait">
                    <span :style="{ color: trait.color }">{{ trait.trait }}</span> - {{ trait.points }} punktów
                </li>
            </ul>
            <hr class="my-4">
            <h1 class="text-center mb-4">Co to wszystko znaczy?</h1>
            <p class="text-justify">Osobowość, przy której uzyskałeś/aś najwięcej punktów jest Twoją dominującą osobowością, a druga za nią jest jej dopełnieniem.</p>
            <p class="text-justify">Jeśli wyniki między dwoma osobowościami wyszły bardzo zbliżone, to powinieneś przyjąć tą, do której Ci bliżej. :)</p>
            <p>Poniżej znajdziesz wskazówki jak interpretować typy osobowości:</p>
            <img :src="require('/src/assets/osobowosci.png')" alt="Wykres pomocniczy. Jeśli widzisz ten tekst skontaktuj się z autorem strony." class="rounded-2xl bg-gray-100 mb-4">
            <div class="grid grid-cols-2 grid-rows-2 gap-3 justify-items-center mb-4 xs:grid-cols-1">
                <TraitCard v-for="(set, index) in traitSets" :key="index" :name="set.name" :traits="set.traits" :bg="set.color"/>
            </div>
        </div>
    </div>

    <div v-else>
        <div class="sm:w-10/12 mx-auto h-screen flex flex-col justify-center">
            <h1 class="text-center mb-3 cmd:w-300 mx-auto">🏴‍☠️ Ahoy! 🦜</h1>
            <p class="text-center">Byli tu piraci i zagarnęli wszystkie wyniki! <br> Rozwiąż test by móc korzystać z tej strony:</p>

            <Button
                class="customButton"
                customClass="mx-auto"
                @click="goToTest"
            >
            <div class="px-6 py-3">
                Rozwiąż test
            </div>
            </Button>
        </div>
    </div>

    <Footer/>
</template>

<script>
import Chart from '../../components/Chart'
import _capitalize from 'lodash/capitalize'
import Button from '../../components/Button'
import TraitCard from '../../components/TraitCard'
import Footer from '../../components/TheFooter'

export default {
    props: ['chartdata'],
    components: {
        Chart,
        Button,
        TraitCard,
        Footer
    },

    data() {
        return {
            traitSets: [
                {name: 'Analityczny', color: '#3498db', traits: ['wolno reaguje', 'skoncentrowany na przyszłości', 'zorganizowany', 'ostrożny w działaniu', 'niezainteresowany kontaktem z otoczeniem', 'dociekliwy', 'dokładny', 'obiektywny']},
                {name: 'Dominujący', color: '#e74c3c', traits: ['szybko reaguje', 'skoncentrowany na teraźniejszości', 'unika braku aktywności', 'pełen zapału', 'nastawiony na rozwiązywanie problemów', 'bezpośredni', 'decyzyjny' ]},
                {name: 'Stabilny', color: '#2ecc71', traits: ['spokojnie reaguje', 'skoncentrowany na teraźniejszości', 'unika konfliktów', 'odrzuca zmiany', 'cierpliwy', 'opanowany', 'wspierający', 'umie słuchać' ]},
                {name: 'Wpływowy', color: '#f1c40f', traits: ['szybko reaguje', 'skoncentrowany na przyszłości', 'unika izolacji', 'działa impulsywnie', 'odrzuca rutynę', 'towarzyski', 'dobry mówca', 'twórczy', 'spontaniczny' ]},
            ]
        }
    },

    methods: {
        goToTest() {
            this.$router.push({ name: 'Test' })
        }
    },

    created() {
        this.data = localStorage.getItem('lastTestResult')
        this.data = JSON.parse(this.data)

        if (!this.data) {
            return
        }

        // Initialize data
        this.dominant = this.data[0]
        this.complementary = this.data[1]

        // Initialize chart data
        const datasets = []
        this.data.forEach(dataset => {
            const obj = {
                label: _capitalize(dataset.trait),
                backgroundColor: (dataset.color),
                stack: '0',
                barThickness: 32,
                data: [dataset.points]
            }
            datasets.push(obj)
        });

        this.chartData = {datasets: datasets}
    }
}
</script>

<style>
body {
    font-size: 16px;
}
</style>