<template>
  <div class="sm:w-10/12 mx-auto">
    <h1 class="text-center mb-3 cmd:w-300 mx-auto">Za chwilę zobaczysz 15 zestawów cech</h1>
    <p>
      W każdym z nich będą 4 cechy.<br> Twoim zadaniem jest uporządkować je
      <b>od najbardziej do najmniej pasującej</b> do Ciebie.
    </p>
    <p>
      Odpowiadaj <b>szczerze</b> - udzielone odpowiedzi wpływają na Twój wynik.
    </p>
    <p>Tu nie ma złych odpowiedzi! 😇</p>

    <div class="flex justify-center xs:flex-col items-center">
      <Button
        class="customButton"
        :value="showIntro"
        @click="$emit('update:showIntro', !showIntro)"
        customClass="mx-1 xs:mb-2"
      >
        <div class="px-6 py-3">
          Zaczynajmy!
        </div>
      </Button>
    </div>
  </div>
</template>

<script>
import Button from "@/components/Button.vue";

export default {
  components: {
    Button,
  },

  props: ["showIntro"],
  emits: ["update:showIntro"],
}
</script>

<style scoped>
p {
  @apply text-center mb-3 xs:w-325 sm:w-325 md:w-full mx-auto ;
}
</style>