<template>
  <draggable
    :list="arr"
    v-bind="dragOptions"
    item-key="type"
    class="draggable__list"
    ghost-class="dragged"
    :component-data="{
      tag: 'ul',
      type: 'transition-group',
      name: !dragging ? 'dragging_trans' : null,
    }"
    @start="this.dragging = true"
    @end="this.dragging = false"
  >
    <template #item="{ element }">
      <li class="draggable__item select-none">
        {{ element.trait }}
      </li>
    </template>
  </draggable>
</template>

<script>
import draggable from "vuedraggable";

export default {
  name: "Question",
  props: ["arr"],

  components: {
    draggable,
  },

  data() {
    return {
      dragging: false,
    };
  },

  computed: {
    dragOptions() {
      return {
        animation: 200,
      };
    },
  },
};
</script>

<style scoped>
.draggable__list {
  width: 300px;
  list-style: none;
}

.draggable__list::before {
  content: "Najbardziej pasująca cecha";
  margin-bottom: 10px;
}

.draggable__list::after {
  content: "Najmniej pasująca cecha";
}

.draggable__list::before,
.draggable__list::after {
  display: block;
  font-size: 16px;
  text-align: center;
  color: #c4c4c4;
}

.draggable__item {
  cursor: grab;

  text-align: center;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 2px;

  border: 1px solid #f1c40f;
}

.draggable__item {
  transition: background-color 0.2s ease;
}

.draggable__item:hover {
  background-color: #f1c40f;
}

.sortable-chosen {
  background-color: #f1c40f;
}

/* .dragged {
  opacity: 0;
} */

.dragged {
  color: transparent;
  background-color: transparent;
  border: 2px dashed #f1c40f77;
}
</style>