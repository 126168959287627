<template>
    <div class="w-full p-4 pb-6 rounded-xl text-black" :style="{ 'background-color': bg }">
        <h1 class="text-center">{{ name }}</h1>
        <ul>
            <li v-for="item in traits" :key="item">
                {{ item }}
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: ['name', 'traits', 'bg'],
}
</script>

<style scoped>
ul {
    @apply list-disc;
}

li {
    @apply ml-4;
}
</style>