import './assets/css/index.css'
import './assets/css/fonts.css'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import VueGtag from 'vue-gtag-next'


const app = createApp(App)
app.use(router)
app.use(VueGtag, {
    property: {
        id: "G-S5DN2BCMJY"
    }
})


createApp(App).use(router).mount('#app')
