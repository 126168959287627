<template>
    <div class="flex flex-col justify-center h-screen">
        <Intro v-if="showIntro" v-model:showIntro="showIntro"/>

        <div class="question flex flex-col justify-center overflow-hidden" v-else-if="!showResultsBool">
            <h1 class="text-center mx-auto mb-2 cmd:w-300">Uporządkuj cechy przeciągając je</h1>
            <p class="text-center mx-auto mb-8 cmd:w-300">Możesz to zrobić łapiąc (przytrzymując) i przeciągając cechę na odpowiednie miejsce.<br>Cechy możesz przeciągać dowolną ilość razy.</p>
            <transition-group :name="transitionName">
                <Question v-for="(question, index) in questionData" v-model:arr="questionData[index]" :key="index" v-show="index + 1 == currentQuestion"/>
            </transition-group>
            
            <div class="flex items-center justify-center mt-6">
                <Button @click="previous_question" :class="previousBtnVisibility">
                    <div class="px-2 py-2">
                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7"></path></svg>
                    </div>
                </Button>
                <span class="mx-4">Zestaw {{ currentQuestion }}/{{ dataLen }}</span>
                <Button @click="next_question" v-if="currentQuestion < dataLen">
                    <div class="px-2 py-2">
                        <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7"></path></svg>
                    </div>
                </Button>
                <Button @click="finishTest" v-if="currentQuestion == dataLen">
                    <div class="px-2 py-2">
                        Zakończ
                    </div>
                </Button>
            </div>
        </div>
    </div>
</template>

<script>
import Intro from './Intro.vue'
import Question from './Question.vue'
import questionData from './questionData'
import _findIndex from 'lodash/findIndex'
import _sortBy from 'lodash/sortBy'
import Button from '../../components/Button'

export default {
    name: 'Test',
    
    components: {
        Intro,
        Question,
        Button,
    },

    data() {
        return {
            questionData: questionData,
            dataLen: questionData.length,
            currentQuestion: 1,

            showIntro: true,
            showResultsBool: false,
            result: null,

            transitionName: 'left'
        }
    },

    methods: {
        next_question() {
            this.currentQuestion++
            this.transitionName = 'left'
        },

        previous_question() {
            this.currentQuestion--
            this.transitionName = 'right'
        },

        calculateResult() {
            let choleryk = 0;
            let melancholik = 0;
            let sangwinik = 0;
            let flegmatyk = 0;

            this.questionData.forEach(list => {
                choleryk += 4 - _findIndex(list, ['type', 'choleryk'])
                melancholik += 4 - _findIndex(list, ['type', 'melancholik'])
                sangwinik += 4 - _findIndex(list, ['type', 'sangwinik'])
                flegmatyk += 4 - _findIndex(list, ['type', 'flegmatyk'])
            })

            let sums =  [
                {trait: 'choleryk', points: choleryk, color: '#e74c3c'}, 
                {trait: 'melancholik', points: melancholik, color: '#3498db'}, 
                {trait: 'sangwinik', points: sangwinik, color: '#f1c40f'}, 
                {trait: 'flegmatyk', points: flegmatyk, color: '#2ecc71'}, 
            ]
            this.result = _sortBy(sums, ['points']).reverse()
        },

        finishTest() {
            this.calculateResult()
            this.showResultsBool = true
            localStorage.setItem('lastTestResult', JSON.stringify(this.result))
            this.$router.push({ name: 'Results' })
        }
    },

    computed: {
        previousBtnVisibility() {
            return this.currentQuestion > 1 ? 'visible' : 'invisible'
        }
    }
}
</script>

<style scoped>
    /*    from  >>  active  >> to    */ 
    .left-enter-active,
    .left-leave-active,
    .right-enter-active,
    .right-leave-active {
        transition: opacity .5s ease, transform .5s ease, height .5s ease;
    }

    .left-enter-from,
    .left-leave-to,
    .right-enter-from,
    .right-leave-to {
        opacity: 0;
        height: 0;
        overflow: hidden;
    }

    .left-enter-to,
    .left-leave-from {
        opacity: 1;
        height: auto;
    }

    .left-enter-to,
    .right-enter-to {
        transform: translateX(0px);
    }

    .left-leave-from,
    .right-leave-to {
        transform: translate(0px);
    }

    .left-enter-from {
        transform: translate(50px);
    }
    .right-enter-from {
        transform: translate(-50px);
    }

    .left-leave-to {
        transform: translateX(-50px);
    }
    .right-leave-to {
        transform: translateX(50px);
    }

    .draggable__list {
        margin-left: auto;
        margin-right: auto;
    }
</style>
