const questionData = [
    [
        {
            type: 'choleryk',
            trait: 'Lubi konkurować' 
        },
        {
            type: 'melancholik',
            trait: 'Zorganizowany' 
        },
        {
            type: 'sangwinik',
            trait: 'Spontaniczny' 
        },
        {
            type: 'flegmatyk',
            trait: 'Cierpliwy' 
        },
    ],
    [
        {
            type: 'choleryk',
            trait: 'Lubi panować nad sytuacją' 
        },
        {
            type: 'melancholik',
            trait: 'Lubi pracować samotnie' 
        },
        {
            type: 'sangwinik',
            trait: 'Lubi pracować z innymi' 
        },
        {
            type: 'flegmatyk',
            trait: 'Zwolennik pracy zespołowej'
         }
    ],
    [
        {
            type: 'choleryk',
            trait: 'Przywódca' 
        },
        {
            type: 'melancholik',
            trait: 'Kieruje się wytycznymi' 
        },
        {
            type: 'sangwinik',
            trait: 'Lubiany' 
        },
        {
            type: 'flegmatyk',
            trait: 'Mediator'
         }
    ],
    [
        {
            type: 'choleryk',
            trait: 'Szybko podejmuje decyzje' 
        },
        {
            type: 'melancholik',
            trait: 'Ostrożny' 
        },
        {
            type: 'sangwinik',
            trait: 'Ma siłę przekonywania' 
        },
        {
            type: 'flegmatyk',
            trait: 'Wrażliwy na innych'
         }
    ],
    [
        {
            type: 'choleryk',
            trait: 'Lubi wyzwania' 
        },
        {
            type: 'melancholik',
            trait: 'Rozważny' 
        },
        {
            type: 'sangwinik',
            trait: 'Lubi zmiany' 
        },
        {
            type: 'flegmatyk',
            trait: 'Nie lubi gwałtownych zmian'
         }
    ],
    [
        {
            type: 'choleryk',
            trait: 'Kieruje biegiem wydarzeń' 
        },
        {
            type: 'melancholik',
            trait: 'Kompetentny' 
        },
        {
            type: 'sangwinik',
            trait: 'Ukierunkowany na współpracę' 
        },
        {
            type: 'flegmatyk',
            trait: 'Ugodowy'
         }
    ],
    [
        {
            type: 'choleryk',
            trait: 'Niezależny' 
        },
        {
            type: 'melancholik',
            trait: 'Poważny' 
        },
        {
            type: 'sangwinik',
            trait: 'Lubi towarzystwo' 
        },
        {
            type: 'flegmatyk',
            trait: 'Ma bliskich przyjaciół'
         }
    ],
    [
        {
            type: 'choleryk',
            trait: 'Doprowadza do realizacji celów' 
        },
        {
            type: 'melancholik',
            trait: 'Rzadko popełnia błędy' 
        },
        {
            type: 'sangwinik',
            trait: 'Impulsywny' 
        },
        {
            type: 'flegmatyk',
            trait: 'Unika konfliktów'
         }
    ],
    [
        {
            type: 'choleryk',
            trait: 'Autorytatywny' 
        },
        {
            type: 'melancholik',
            trait: 'Logiczny' 
        },
        {
            type: 'sangwinik',
            trait: 'Twórczy' 
        },
        {
            type: 'flegmatyk',
            trait: 'Wyrozumiały'
         }
    ],
    [
        {
            type: 'choleryk',
            trait: 'Bezpośredni' 
        },
        {
            type: 'melancholik',
            trait: 'Analityczny' 
        },
        {
            type: 'sangwinik',
            trait: 'Otwarty' 
        },
        {
            type: 'flegmatyk',
            trait: 'Potrafi słuchać'
         }
    ],
    [
        {
            type: 'choleryk',
            trait: 'Rozwiązuje problemy' 
        },
        {
            type: 'melancholik',
            trait: 'Myśli obiektywnie' 
        },
        {
            type: 'sangwinik',
            trait: 'Ma intuicję' 
        },
        {
            type: 'flegmatyk',
            trait: 'Dyplomatyczny'
         }
    ],
    [
        {
            type: 'choleryk',
            trait: 'Niecierpliwy' 
        },
        {
            type: 'melancholik',
            trait: 'Dokładny' 
        },
        {
            type: 'sangwinik',
            trait: 'Entuzjastyczny' 
        },
        {
            type: 'flegmatyk',
            trait: 'Lojalny'
         }
    ],
    [
        {
            type: 'choleryk',
            trait: 'Przyjmuje odpowiedzialność' 
        },
        {
            type: 'melancholik',
            trait: 'Sumienny' 
        },
        {
            type: 'sangwinik',
            trait: 'Wylewny' 
        },
        {
            type: 'flegmatyk',
            trait: 'Zrównoważony'
         }
    ],
    [
        {
            type: 'choleryk',
            trait: 'Niespokojny' 
        },
        {
            type: 'melancholik',
            trait: 'Konsekwentny' 
        },
        {
            type: 'sangwinik',
            trait: 'Rozmowny' 
        },
        {
            type: 'flegmatyk',
            trait: 'Przewidywalny'
         }
    ],
    [
        {
            type: 'choleryk',
            trait: 'Lubi ryzyko' 
        },
        {
            type: 'melancholik',
            trait: 'Systematyczny' 
        },
        {
            type: 'sangwinik',
            trait: 'Swobodny' 
        },
        {
            type: 'flegmatyk',
            trait: 'Taktowny'
         }
    ]
]

export default questionData;