<template>
  <div class="select-none mx-1 mb-2">
    <button :class="`button_comp ${customClass}`" type="button">
      <slot class="select-none"></slot>
    </button>
  </div>
</template>

<script>
export default {
  props: ['customClass']
}
</script>

<style>
.button_comp {
  all: unset;
  cursor: pointer;
  display: block;
  background-color: var(--primary);
  border-radius: 2px;
}
</style>
