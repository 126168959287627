<template>
    <div class="sticky top-0">
        <canvas id="chart" height="32"></canvas>
    </div>
</template>

<script>
import Chart from 'chart.js'
// eslint-disable-next-line
import ChartDataLabels from 'chartjs-plugin-datalabels'


const fontSizeStr = window.getComputedStyle(document.body, null).getPropertyValue('font-size')
const rem = num => num * parseFloat(fontSizeStr)

const options = {
    maintainAspectRatio: false,
    tooltips: {
        enabled: false
    },
    legend: {
        display: false,
        position: 'bottom',
        labels: {
            fontFamily: 'Rubik',
        }
    },
    scales: {
        xAxes: [{
            display: false,
            ticks: {
                beginAtZero: true,
                max: 150
            },
        }],
        yAxes: [{
            display: false,
        }]
    },
    plugins: {
        datalabels: {
            anchor: 'end',
            align: 'end',
            offset: `${rem(-1.75)}`,
            color: '#1A1A1A',
            labels: {
                title: {
                    font: {
                        weight: '400',
                        family: 'Rubik',
                        size: `${rem(0.8)}`
                    }
                }
            }
        }
    }
}

export default {
    props: ['chartData'],

    data() {
        return {
            chartOptions: options
        }
    },

    mounted() {
        const chartCanvas = document.querySelector('#chart')
        chartCanvas.height = 32

        new Chart(chartCanvas, {
            type: 'horizontalBar',
            data: this.chartData,
            options: this.chartOptions
        })
    }
}
</script>