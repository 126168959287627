<template>
    <div class="p-2 flex justify-center items-center md:flex-col md:bg-white md:py-12 md:px-4 md:rounded-lg md:shadow-md">
        <div class="w-16 h-16 xs:mr-8 sm:mr-8 md:mr-0 md:mb-4 bg-primary rounded-full flex-shrink-0 flex justify-center items-center">
            <img class="w-12 h-12" :src="icon">
        </div>
        <span class="xs:w-160 sm:w-300 md:w-160 md:text-center">{{ desc }}</span>
    </div>
</template>

<script>
export default {
    props: ['icon', 'desc']
}
</script>